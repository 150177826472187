.finbe-login-main{
    background-image:url('/icons/background-login.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    width: 100%;
    top: 0;
    bottom:0;
    right: 0;
    left: 0;
}
.finbe-login-transparent{
    position: absolute;
    width: 50%;
    height: 100%;
    background: white;
    opacity: .7;

    
}

.finbe-login-content{
    width: 25em;
    height: 30em;
    position: absolute;
    background: white;
    opacity: 1;
    margin-top: 5em;
    margin-bottom: 5em;
    margin-left: 7.5em;
    margin-right: 7.5em;
    border-radius: 4%;
    z-index: 10;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));

}
.finbe-login-logo{
    width: 10em;
    padding: 2em 7em 0em 7em;
}
.finbe-login-form{
    width: 90%;
    padding: 2em 1em;
}
.finbe-login-form form div span{
    margin-bottom: 1em;
}
.finbe-login-buttons{
    width: 100%;
}
.finbe-login-button{
   margin: 0 7em;
}
.finbe-login-buttons a,.finbe-login-buttons span{
    margin-left: 6em;
    white-space: nowrap;
    color: gray;
    text-decoration: none;
    cursor: pointer;
 }
.finbe-login-form input{
    border-radius: 5px !important;
}
.finbe-login-form label{
    margin-left: 10px !important;
}
