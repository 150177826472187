@font-face {
  font-family: 'finbe';
  src: url('/fonts/finbe.eot?93058645');
  src: url('/fonts/finbe.eot?93058645#iefix') format('embedded-opentype'),
       url('/fonts/finbe.woff2?93058645') format('woff2'),
       url('/fonts/finbe.woff?93058645') format('woff'),
       url('/fonts/finbe.ttf?93058645') format('truetype'),
       url('/fonts/finbe.svg?93058645#finbe') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'finbe';
    src: url('../font/finbe.svg?93058645#finbe') format('svg');
  }
}
*/

.icon::before {
  font-family: "finbe";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon.aprobado-stepper::before { content: '\e804'; } /* '' */
.icon.aprobado::before { content: '\e805'; } /* '' */
.icon.archivar::before { content: '\e806'; } /* '' */
.icon.auditorías---desactivado::before { content: '\e807'; } /* '' */
.icon.auditorías::before { content: '\e808'; } /* '' */
.icon.cargar-documentos-activo::before { content: '\e809'; } /* '' */
.icon.cargar-documentos-sin-documento::before { content: '\e80a'; } /* '' */
.icon.catalogos---desactivado::before { content: '\e80b'; } /* '' */
.icon.catalogos::before { content: '\e80c'; } /* '' */
.icon.clientes-usuarios---desactivado::before { content: '\e80d'; } /* '' */
.icon.clientes-usuarios-::before { content: '\e80e'; } /* '' */
.icon.configuraciones---desactivado::before { content: '\e80f'; } /* '' */
.icon.configuraciones-::before { content: '\e810'; } /* '' */
.icon.datos_stepper::before { content: '\e811'; } /* '' */
.icon.desaprobado::before { content: '\e812'; } /* '' */
.icon.desarchivar::before { content: '\e813'; } /* '' */
.icon.descargar::before { content: '\e814'; } /* '' */
.icon.desplegar::before { content: '\e815'; } /* '' */
.icon.documento-csv::before { content: '\e816'; } /* '' */
.icon.documentos-a-firmar-_-stepper::before { content: '\e817'; } /* '' */
.icon.editar::before { content: '\e818'; } /* '' */
.icon.eliminar::before { content: '\e819'; } /* '' */
.icon.empresa_stepper::before { content: '\e81a'; } /* '' */
.icon.enviar-a-finbe---enviado::before { content: '\e81b'; } /* '' */
.icon.enviar-a-finbe::before { content: '\e81c'; } /* '' */
.icon.enviar::before { content: '\e81d'; } /* '' */
.icon.financiamiento---desactivado::before { content: '\e81e'; } /* '' */
.icon.financiamiento-::before { content: '\e81f'; } /* '' */
.icon.firma-con-fiel::before { content: '\e820'; } /* '' */
.icon.firma-manual::before { content: '\e821'; } /* '' */
.icon.firma_stepper::before { content: '\e822'; } /* '' */
.icon.firmar::before { content: '\e823'; } /* '' */
.icon.observaciones::before { content: '\e825'; } /* '' */
.icon.organizar::before { content: '\e826'; } /* '' */
.icon.reintentar::before { content: '\e827'; } /* '' */
.icon.remover--eliminar::before { content: '\e828'; } /* '' */
.icon.reportes--desactivado::before { content: '\e829'; } /* '' */
.icon.reportes::before { content: '\e82a'; } /* '' */
.icon.representante-legal::before { content: '\e82b'; } /* '' */
.icon.resumen_stepper::before { content: '\e82c'; } /* '' */
.icon.trazado-270::before { content: '\e82d'; } /* '' */
.icon.unidades_stepper::before { content: '\e82e'; } /* '' */
.icon.usuarios-asignados::before { content: '\e82f'; } /* '' */
.icon.vista-calendario::before { content: '\e830'; } /* '' */
.icon.visto-bueno::before { content: '\e831'; } /* '' */
.icon.visualizar-evidencia::before { content: '\e832'; } /* '' */
.icon.visualizar::before { content: '\e833'; } /* '' */
