@font-face {
    font-family: 'MontserratBold';
    src: url('/fonts/Montserrat-Bold.otf');
}

@font-face {
    font-family: 'MontserratSemiBold';
    src: url('/fonts/Montserrat-SemiBold.otf');
}

@font-face {
    font-family: 'MontserratRegular';
    src: url('/fonts/Montserrat-Regular.otf');
}

@font-face {
    font-family: 'BasisGrotesqueProBold';
    src: url('/fonts/basis/BasisGrotesquePro-Bold.ttf');
}

@font-face {
    font-family: 'BasisGrotesqueProLight';
    src: url('/fonts/basis/BasisGrotesquePro-Light.ttf');
}

@font-face {
    font-family: 'BasisGrotesqueProRegular';
    src: url('/fonts/basis/BasisGrotesquePro-Regular.ttf');
}