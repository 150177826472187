.k-icon.icon::before {
    vertical-align: middle;
    opacity: 1;
}

.icon.none::before {
    content: "";
    margin: -10px;
    padding-right: 16px;
}
.icon.one::before {
    content: url('/icons/ICONOGRAFIA/1.svg');
    margin: -10px;
}

.icon.two::before {
    content: url('/icons/ICONOGRAFIA/2.svg');
    margin: -10px;
}

.icon.three::before {
    content: url('/icons/ICONOGRAFIA/3.svg');
    margin: -10px;
}

.icon.aniadir::before {
    content: url('/icons/ICONOGRAFIA/AÑADIR.svg');
}

.icon.aprobado_stepper::before {
    content: url('/icons/ICONOGRAFIA/APROBADO STEPPER.svg');
}

.icon.aprobado::before {
    content: url('/icons/ICONOGRAFIA/APROBADO.svg');
}

.icon.archivar::before {
    content: url('/icons/ICONOGRAFIA/ARCHIVAR.svg');
}

.icon.auditorias::before {
    content: url('/icons/ICONOGRAFIA/AUDITORÍAS.svg');
}

.icon.cargar_docs_activo::before {
    content: url('/icons/ICONOGRAFIA/CARGAR DOCUMENTOS ACTIVO.svg');
}

.icon.cargar_docs_sin_doc::before {
    content: url('/icons/ICONOGRAFIA/CARGAR DOCUMENTOS SIN DOCUMENTO.svg');
}

.icon.datos_stepper::before {
    content: url('/icons/ICONOGRAFIA/DATOS_STEPPER.svg');
}

.icon.desaprobado::before {
    content: url('/icons/ICONOGRAFIA/DESAPROBADO.svg');
}

.icon.rechazado::before {
    fill: #FFF;
    content: url('/icons/ICONOGRAFIA/RECHAZADO.svg');
}

.icon.desarchivar::before {
    content: url('/icons/ICONOGRAFIA/DESARCHIVAR.svg');
}

.icon.descargar::before {
    content: url('/icons/ICONOGRAFIA/DESCARGAR.svg');
}

.icon.desplegar::before {
    content: url('/icons/ICONOGRAFIA/DESPLEGAR.svg');
}

.icon.documento_csv::before {
    content: url('/icons/ICONOGRAFIA/DOCUMENTO CSV.svg');
}

.icon.docs_a_firmar_stepper::before {
    content: url('/icons/ICONOGRAFIA/DOCS_A_FIRMAR_STEPPER.svg');
}

.icon.editar::before {
    content: url('/icons/ICONOGRAFIA/EDITAR.svg');
}

.icon.eliminar::before {
    content: url('/icons/ICONOGRAFIA/ELIMINAR.svg');
}

.icon.empresa_stepper::before {
    content: url('/icons/ICONOGRAFIA/EMPRESA_STEPPER.svg');
}

.icon.enviar_a_finbe::before {
    content: url('/icons/ICONOGRAFIA/ENVIAR A FINBE.svg');
}

.icon.enviar_a_finbe_enviado::before {
  width: 25px;
  content: url('/icons/ICONOGRAFIA/ENVIAR A FINBE - ENVIADO.svg');
}

.icon.enviar::before {
    content: url('/icons/ICONOGRAFIA/ENVIAR.svg');
}

.icon.financiamiento::before {
    content: url('/icons/ICONOGRAFIA/FINANCIAMIENTO .svg');
}

.icon.firma_con_fiel::before {
    content: url('/icons/ICONOGRAFIA/FIRMA CON FIEL.svg');
}

.icon.firma_manual::before {
    content: url('/icons/ICONOGRAFIA/FIRMA MANUAL.svg');
}

.icon.firma_stepper::before {
    content: url('/icons/ICONOGRAFIA/FIRMA_STEPPER.svg');
}

.icon.firmar::before {
    content: url('/icons/ICONOGRAFIA/FIRMAR.svg');
}

.icon.hamburguesa::before {
    content: url('/icons/ICONOGRAFIA/HAMBURGUESA ICONO.svg');
}

.icon.observaciones::before {
    content: url('/icons/ICONOGRAFIA/OBSERVACIONES.svg');
}

.icon.organizar::before {
    content: url('/icons/ICONOGRAFIA/ORGANIZAR.svg');
}

.icon.representante_legal::before {
    content: url('/icons/ICONOGRAFIA/REPRESENTANTE LEGAL.svg');
}

.icon.resumen_stepper::before {
    content: url('/icons/ICONOGRAFIA/RESUMEN_STEPPER.svg');
}

.icon.unidades_stepper::before {
    content: url('/icons/ICONOGRAFIA/UNIDADES_STEPPER.svg');
}

.icon.usuarios_asignados::before {
    content: url('/icons/ICONOGRAFIA/USUARIOS ASIGNADOS.svg');
}

.icon.visto_bueno::before {
    content: url('/icons/ICONOGRAFIA/VISTO BUENO.svg');
}

.icon.visualizar_evidencia::before {
    content: url('/icons/ICONOGRAFIA/VISUALIZAR EVIDENCIA.svg');
}

.icon.visualizar::before {
    content: url('/icons/ICONOGRAFIA/VISUALIZAR.svg');
}

.icon.regresar::before {
    content: url('/icons/ICONOGRAFIA/REGRESAR.svg');
}

.icon.signo-pesos::before {
  content: url('/icons/ICONOGRAFIA/SIGNO-PESOS.svg');
}

.icon.circulo-rojo-notificacion::before {
  content: url('/icons/ICONOGRAFIA/CIRCULO-ROJO-NOTIFICACIONES.svg');
}
.icon.documentos-firma-stepper{
    content: url('/icons/ICONOGRAFIA/DOCS_A_FIRMAR_STEPPER.svg');
}
.icon.firmar{
    content: url('/icons/ICONOGRAFIA/FIRMAR.svg');
}

.icon.interrogacion{
    content: url('/icons/ICONOGRAFIA/INTERROGACION.svg');
}

.icon.remover-eliminar{
    content: url('/icons/ICONOGRAFIA/REMOVER- ELIMINAR.svg');
}

.icon.reintentar{
    content: url('/icons/ICONOGRAFIA/REINTENTAR.svg');
}

/** Stepper **/
.icon.stepper-uno-n{
    content: url('/icons/ICONOGRAFIA/stepper/1 N.svg');
}

.icon.stepper-uno{
    content: url('/icons/ICONOGRAFIA/stepper/1.svg');
}

.icon.stepper-dos-n{
    content: url('/icons/ICONOGRAFIA/stepper/2 N.svg');
}

.icon.stepper-dos{
    content: url('/icons/ICONOGRAFIA/stepper/2.svg');
}

.icon.stepper-tres-n{
    content: url('/icons/ICONOGRAFIA/stepper/3 N.svg');
}

.icon.stepper-tres{
    content: url('/icons/ICONOGRAFIA/stepper/3.svg');
}

.icon.stepper-{
    content: url('/icons/ICONOGRAFIA/stepper/.svg');
}

.icon.stepper-aprobado-n{
    content: url('/icons/ICONOGRAFIA/stepper/APROBADO NSTEPPER.svg');
}

.icon.stepper-aprobado{
    content: url('/icons/ICONOGRAFIA/stepper/APROBADO STEPPER.svg');
}

.icon.stepper-datos-n{
    content: url('/icons/ICONOGRAFIA/stepper/DATOS_NSTEPPER.svg');
}

.icon.stepper-datos{
    content: url('/icons/ICONOGRAFIA/stepper/DATOS_STEPPER.svg');
}

.icon.stepper-csv-n{
    content: url('/icons/ICONOGRAFIA/stepper/DOCUMENTO CSV_NSTEPPER.svg');
}

.icon.stepper-csv{
    content: url('/icons/ICONOGRAFIA/stepper/DOCUMENTO CSV_STEPPER.svg');
}

.icon.stepper-docs-firma-n{
    content: url('/icons/ICONOGRAFIA/stepper/DOCUMENTOS A FIRMAR _ NSTEPPER.svg');
}

.icon.stepper-docs-firma{
    content: url('/icons/ICONOGRAFIA/stepper/DOCUMENTOS A FIRMAR _ STEPPER.svg');
}

.icon.stepper-empresa-n{
    content: url('/icons/ICONOGRAFIA/stepper/EMPRESA_NSTEPPER.svg');
}

.icon.stepper-empresa{
    content: url('/icons/ICONOGRAFIA/stepper/EMPRESA_STEPPER.svg');
}

.icon.stepper-firma-n{
    content: url('/icons/ICONOGRAFIA/stepper/FIRMA_NSTEPPER.svg');
}

.icon.stepper-firma{
    content: url('/icons/ICONOGRAFIA/stepper/FIRMA_STEPPER.svg');
}

.icon.stepper-representante-legal-n{
    content: url('/icons/ICONOGRAFIA/stepper/REPRESENTANTE LEGAL_NSTEPPER.svg');
}

.icon.stepper-representante-legal{
    content: url('/icons/ICONOGRAFIA/stepper/REPRESENTANTE LEGAL_STEPPER.svg');
}

.icon.stepper-resumen-n{
    content: url('/icons/ICONOGRAFIA/stepper/RESUMEN_NSTEPPER.svg');
}

.icon.stepper-resumen{
    content: url('/icons/ICONOGRAFIA/stepper/RESUMEN_STEPPER.svg');
}

.icon.stepper-unidades-n{
    content: url('/icons/ICONOGRAFIA/stepper/UNIDADES_NSTEPPER.svg');
}

.icon.stepper-unidades{
    content: url('/icons/ICONOGRAFIA/stepper/UNIDADES_STEPPER.svg');

}
.icon.vista-calendario{
    content: url('/icons/ICONOGRAFIA/VISTA CALENDARIO.svg');

}

.icon.usuarios-asignados-black::before {
  content: '\e82f';
  color: #000;
  padding: 5px;
}
