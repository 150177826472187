@import 'icons.css';
@import 'finbe-fonts.css';

html, body {
    width: 100%;
    font-family: "BasisGrotesqueProRegular";
    background-color: #f4f3ef;
    margin: 0;
    padding: 0;
}

.finbe-header {
    width: 100%;
    height: 4.5em; /* 72 px*/
    background-color: #D2D0D2;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 11;
    box-shadow: 0 0 20px #b5b1a8;
    display: flex;
    justify-content: space-between;
}

.finbe-header-logo {
    width: 153px;
    height: 44px;
    margin-top: 14px;
    margin-left: 50px;
}

.finbe-nav {
  display: flex;
  margin-top: 14px;
  margin-right: 20px;
  justify-self: flex-end;
}

/* Finbe Avatar Header */
.finbe-avatar {
  display: flex;
  margin-right: 10px;
}

.finbe-avatar-description {
  margin-right: 16px;
  text-align: right;
}

.finbe-avatar-name {
  font-size: 16px;
  font-weight: bold;
  color: #3E2818;
  margin-bottom: 2px;
}

.finbe-avatar-rol {
  color: #9A9A9A;
  font-size: 14px;
  font-weight: bold;
}

/* End Finbe Avatar Header*/
.finbe-header-burger {
  width: 24px;
  height: 24px;
  top: 6px;
}

.finbe-header-burger img{
  width: 24px;
  height: 24px;
}

.finbe-aside{
    width: 240px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding-top: 72px;
    background-color: #FFF;
    z-index: 10;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.161);
    overflow: hidden auto;
    /* filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161)) */
}

/* Finbe Menu */
.finbe-menu  {
  padding-top: 2em; /* 32px */
}

.finbe-menu > div {
  border-width: 0;
  border-style: none;
}

.finbe-menu-item {
  font-family: "BasisGrotesqueProLight" !important;
  font-size: 18px;
  padding-left: 20px;
  color: #9A9A9A;
}
.finbe-menu-item-content span{
  vertical-align: middle;
  line-height: normal;
  display: inline;
  white-space: normal !important;
  max-width: 160px; 
}

.finbe-menu-icon {
  top: auto;
  vertical-align: unset !important;
  display: inline;
}

.finbe-menu-icon.finbe-arrow {
  position: absolute;
  right: 20px;
  padding-top: 4px;
  font-weight: bold;
}

[aria-selected="true"].finbe-menu-item {
  color: #0071D4;
  background-color: white;
}

[aria-selected="true"].finbe-menu-item.header {
  font-weight: bold;
}

[aria-selected="true"].finbe-menu-item.header .finbe-menu-item-content {
  margin-left: 16px;
}

[aria-selected="true"].finbe-menu-item.header::before {
  content: url("/icons/item-before.svg");
  margin-left: -20px;
  margin-top: -4px;
  width: 4px;
  height: 40px;
  border-radius: 3px;
  background-color:#0071D4;
}

.finbe-menu-item:hover {
  color: #0262AA !important;
  background-color: transparent;
}

.finbe-menu-item:focus {
  color: #0262AA !important;
  background-color: transparent;
  box-shadow: none;
}

.finbe-menu-item:focus:hover {
  color: #0262AA !important;
  background-color: transparent;
  box-shadow: none;
}

[aria-selected="true"].finbe-menu-item:focus {
  color: #0262AA !important;
  background-color: transparent;
  box-shadow: none;
}

[aria-selected="true"].finbe-menu-item:hover {
  background-color: transparent;

}

/* .k-drawer-push > div > div > ul > li > span.k-i-arrow-chevron-right,
.k-drawer-push > div > div > ul > li > span.k-i-arrow-chevron-down {
  right: 0px !important;
}

.k-drawer-container .k-item-text {
  display: none;
}

.k-drawer-container.k-drawer-expanded .k-item-text {
  display: unset;
} */

/* End Finbe Menu */


.finbe-wrapper{
    margin-top: 72px;
    margin-left: 232px;
    padding-top: 20px;
    padding-right: 24px;
    padding-left: 24px;
}

.finbe-title-card {
    height: 54px; /*  2.75em; */
    background-color: #FFF;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.161);
    /* filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161)); */
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 26px;
    font-weight: bold;
    color: #5b5b5f;
    vertical-align: middle;
}

.finbe-title-return {
    background-color: transparent;
    margin-right: 10px;
    padding: 0;
    border-color: transparent;
    display: inline-block;
    vertical-align: top;
    opacity: 1;
    width: 28px;
    height: 28px;
}

.finbe-title-return::before, .finbe-title-return:hover,
.finbe-title-return:hover::before {
    background-color: transparent;
    border-color: transparent;
    opacity: 1;
    margin-right: 10px;
    transition: none;
}

.finbe-title-buttons {
    float: right;
}

.finbe-content-card{
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 3px 6px rgba(0,0,0,0.161);
    /* filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161)); */
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 20px;
}

/* Table */

.finbe-table {
  border-style: none;
  font-family: "BasisGrotesqueProRegular" !important;
  margin-top: 10px;
}

.finbe-table .k-grid-header {
  border-color: transparent;
  background-color: white;
  padding-right:0px !important;
}

.finbe-table .k-grid-header-wrap  tr {
  background-color: #D2D0D2 !important;
  font-weight: bold;
  color: black;
}

.finbe-table .k-grid-header-wrap  tr  th:first-of-type {
  --radius: 10px;
  border-top-left-radius: var(--radius) !important;
  border-bottom-left-radius: var(--radius) !important;
}


.finbe-table .k-grid-header-wrap  .k-i-sort-desc-sm::before {
  content: url("/icons/ICONOGRAFIA/ORGANIZAR.svg");
  color: black;
  position: absolute;
  right: 10px;
}

.finbe-table .k-grid-header-wrap .k-i-sort-asc-sm::before {
  content: url("/icons/ICONOGRAFIA/ORGANIZAR.svg");
  color: black;
  position: absolute;
  right: 10px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.finbe-table .k-grid-header-wrap  tr th:last-of-type {
  --radius: 10px;
  border-top-right-radius: var(--radius) !important;
  border-bottom-right-radius: var(--radius) !important;
}

.finbe-table .k-grid-content {
  overflow-y: auto;
  overflow-x: auto;
}

.finbe-table .k-grid-content  tr {
  background-color: white;
  border-bottom: 4px;
  border-color: #D2D0D2;
}

.finbe-table .k-grid-content  tr > td {
  border-left-width: 0;
  border-bottom-width: 1px;
}

/* Pager */
.finbe-table .k-grid-pager {
  border-style: none;
  background-color: white;
  justify-content: center;
  margin-top: 10px;
}

.finbe-table .k-pager-nav {
  border-style: none;
}


.finbe-table .k-pager-wrap > .k-pager-numbers-wrap > ul > li.k-link {
  border-style: none;
}

.finbe-table  .k-pager-numbers .k-link {
  border-style: none;
  font-family: 'Montserrat';
  font-size: 8pt;
  font-weight: bold;
  color: black;
  border-radius: 50%;
}

.finbe-table .k-pager-numbers .k-link:hover {
  background-color: #0071D4;
  color: white;
}

.finbe-table .k-pager-numbers .k-link.k-state-selected {
  background-color: #0071D4;
  border-style: none;
  color:white;
}

.finbe-table .k-pager-info .k-label{
  content:"";
}

/* End Table*/

.finbe-button{
    border-radius: 5px;
    font-family: "BasisGrotesqueProLight" !important;
    font-weight: bold;
    color: #FFF;
    font-size: 16px;
    margin-left: 10px;
}

/** Button primary **/
.finbe-button.primary{
    background-color: #0071D4 !important;
    border: none !important;
}

/** Button primary hover **/
.finbe-button.primary:hover{
    background-color:  #0262AA !important;
}

/** Button primary active **/
.finbe-button.primary:active{
    background-color:  #0071D4 !important;
}

/** Button primary focus **/
.finbe-button.primary:focus{
  box-shadow: 0 0 0 0 transparent !important;
}

/** Button secondary **/
.finbe-button.secondary{
    background-color: #FFCF1B !important;
    border: none !important;
}

/** Button secondary hover **/
.finbe-button.secondary:hover{
    background-color:  #EDAF22 !important;
}

/** Button secondary hover **/
.finbe-button.secondary:active{
    background-color:  #DDA917 !important;
}

/** Button secondary focus **/
.finbe-button.secondary:focus{
  box-shadow: 0 0 0 0 transparent !important;
}

/** Button danger color **/
.finbe-button.danger {
    border-color: #dc3545 !important;
    background-color: #dc3545 !important;
    border-radius: 0.25em;
    padding-bottom: 4px;
}

/** Button danger hover **/
.finbe-button.danger:hover{
    background-color:  #CA1D2E !important;
}

/** Button danger focus **/
.finbe-button.danger:focus{
  box-shadow: 0 0 0 0 transparent !important;
}

/** Button tertiary color **/
.finbe-button.tertiary{
    background-color: #9A9A9A !important;
    border: none !important;
}

/** Button tertiary hover color **/
.finbe-button.tertiary:hover{
    background-color:  #B8B8B8 !important;
}

/** Button tertiary active color **/
.finbe-button.tertiary:active{
    background-color:  #9A9A9A !important;
}

/** Button tertiary focus **/
.finbe-button.tertiary:focus{
  box-shadow: 0 0 0 0 transparent !important;
}

/** Button success color **/
.finbe-button.success{
    background-color: #28a745 !important;
    border-color: #28a745 !important;
    border-radius: 0.25em;
    padding-bottom: 4px;
}

/** Button success hover color**/
.finbe-button.success:hover{
    background-color:  #25923D !important;
}

/** Container App **/
.finbe-app-container{
  /* padding-left: 2.5em;
  padding-right: 2.5em;
  padding-top: 1.2em;
  padding-bottom: 1.2em; */
  /*height: calc((100vw/2 - 30px) * 1.34);*/
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* background-color: #f0efeb; */
}

/** Section padding bottom **/
.finbe-app-section-bottom{
  padding-bottom: 1.2em;
}

/** Section padding top **/
.finbe-app-section-top{
  padding-top: 0em;
}

/** Line App **/
.finbe-app-line{
  width: 97%;
  border-bottom: 1px solid #b5b1a8;
}

/** Color text **/
.finbe-text-color{
  color: #9A9A9A;
}

/** Add padding top for show text **/
.k-form > *:first-child, .k-form .k-daterangepicker .k-textbox-container:first-child {
  padding-top: 10px;
}

/** Removed border radius for show completed text **/
.lista-filtros > div > .k-datepicker, .k-form-field {
  padding-top: 10px;
  border-radius: 0;
}

/** Center text in label hover input - usign .finbe-form-field-wrap for working**/
div.k-form-field-wrap.finbe-form-field-wrap> span.k-textbox-container> label.k-label{
  width: 100%;
  text-align: center;
  display: block;
  font-weight: bold;
}

/** Change desing input **/
div.k-form-field-wrap.finbe-form-field-wrap> span.k-textbox-container> input.k-textbox{
  border: 5px solid #d2d0d2;
  background-color: #f1f0ec;
  text-align: right;
  color: #0071d4;
  font-size: 20px;
  font-weight: bold;
  padding-right: 1.4em;
  padding-bottom: 0.4em;
  margin-top: -0.18em;
}

.k-widget.k-combobox.k-header.k-combobox-clearable{
  background-color: transparent;
}

/** Adding icon **/
div.k-form-field-wrap.finbe-form-field-wrap> span.k-textbox-container::before{
  /**content: '<span class="k-icon k-i-dollar"/>';
  background-size:cover;
  flex: 1 1 auto;
  width: 15px;
  height: 15px;**/
}

/** Change border radius in ComboBox **/
div.k-form-field-wrap.finbe-form-combo> span.k-textbox-container> span.k-combobox> span.k-dropdown-wrap{
  border-radius: 0.5em;
  border: 3px solid #d7d6d3;
  margin-top: -0.18em;
}

/** Change border radius in ComboBox in more filter**/
div.k-form-field-wrap.finbe-form-combo> span.k-combobox> span.k-dropdown-wrap{
  border-radius: 0.5em;
  border: 3px solid #d7d6d3;
  margin-top: -0.18em;
}

/** Removed border radius in FieldWrapper **/
.lista-filtros > div > .k-datepicker, .k-form-field {
  border-radius: 0px !important;
  margin-top: 1em;
  padding: 0.3em
}

/** Change desing in ComboBox **/
div.k-form-field-wrap.finbe-form-field-filter> input.k-textbox{
  border-color: #d2d0d2;
  background-color: #d2d0d2;
  width: 100%;
}

/** Change color input text **/
div.k-form-field> div.k-form-field-wrap.finbe-form-field-filter> span.k-textbox-container> input.k-textbox{
  border-radius: 0.5em;
}

/** Change Margin Button filter **/
.finbe-btn-margin{
  margin-left: 10px;
  margin-top: 10px;
}

/** Icon left in button **/
button.k-button.k-icon-left> span.k-icon{
  margin-left: 5px;
  order: 2;
}

/**  Div filter input **/
.finbe-div-filter-input{
  width: 30%;
}

/** Margin left div buttom and position left **/
.finbe-btn-left{
  position:absolute;
  top:1;
  right:0;
  margin-right: 5.5em;
}

/** **/
.k-form .k-form-field, .k-form .k-form-buttons, .k-form .k-daterangepicker .k-textbox-container {
 margin-top:  0em;
}

/** Desig date picker ranger **/
div.k-form-field> span.k-daterangepicker> span.k-daterangepicker-wrap {
  display: flex !important;
  margin-top: 0.1em;
}

/** DateRangePicker in line  **/
div.k-form-field> span.k-daterangepicker> span.k-daterangepicker-wrap>
span.k-textbox-container>span.k-widget.k-dateinput.finbe-filter-date-picker-color{
  flex: 0;
  padding-right: 1.2em;
  padding-top: 0.5em !important;
}

.finbe-filter-date-picker-one{
  margin-top: -0.13em;
}

.finbe-filter-date-picker-two {
  margin-top: -0.98em;
}

/** DateRangePicker color **/
div.k-form-field> span.k-daterangepicker> span.k-daterangepicker-wrap>
span.k-textbox-container>span.k-widget.k-dateinput.finbe-filter-date-picker-color> span.k-dateinput-wrap> input{
  border-color: #d2d0d2;
  background-color: #d2d0d2;
  color: #798087;
}

/** Header table (Grid) **/
div.k-widget.k-grid.finbe-table-headers> div.k-grid-header> div.k-grid-header-wrap> table> thead{
  background-color: #d9d8d5;
  border-radius: 0.2em;
}

/** Stepper change size **/
nav.k-stepper.finbe-stepper-horizontal> ol.k-step-list.k-step-list-horizontal>
li.k-step> a.k-step-link> span.k-step-indicator {
  width: 3.3em;
  height: 3.3em;
}

/** Stepper change color done **/
nav.k-stepper.finbe-stepper-horizontal> ol.k-step-list.k-step-list-horizontal>
li.k-step.k-step-done> a.k-step-link> span.k-step-indicator  {
  background-color: #fa717f;
  border-color: #fa717f;
}

/** Stepper change color current **/
nav.k-stepper.finbe-stepper-horizontal> ol.k-step-list.k-step-list-horizontal>
li.k-step.k-step-current> a.k-step-link> span.k-step-indicator  {
  background-color: #fa717f;
  border-color: #fa717f;
}

/** Stepper Create Company **/
nav.k-stepper.finbe-stepper-horizontal> ol.k-step-list.k-step-list-horizontal>
li.k-step.k-step-done> a.k-step-link> span.k-step-indicator> span.stepper-unidades-n{
  content: url('/icons/ICONOGRAFIA/stepper/UNIDADES_STEPPER.svg');
}

nav.k-stepper.finbe-stepper-horizontal> ol.k-step-list.k-step-list-horizontal>
li.k-step.k-step-current> a.k-step-link> span.k-step-indicator> span.stepper-unidades-n  {
  content: url('/icons/ICONOGRAFIA/stepper/UNIDADES_STEPPER.svg');
}

/** Stepper Create CSV current and done**/
nav.k-stepper.finbe-stepper-horizontal> ol.k-step-list.k-step-list-horizontal>
li.k-step.k-step-done> a.k-step-link> span.k-step-indicator> span.stepper-csv-n{
  content: url('/icons/ICONOGRAFIA/stepper/DOCUMENTO CSV_STEPPER.svg');
}

nav.k-stepper.finbe-stepper-horizontal> ol.k-step-list.k-step-list-horizontal>
li.k-step.k-step-current> a.k-step-link> span.k-step-indicator> span.stepper-csv-n  {
  content: url('/icons/ICONOGRAFIA/stepper/DOCUMENTO CSV_STEPPER.svg');
}

/** Stepper Create Resumen **/
nav.k-stepper.finbe-stepper-horizontal> ol.k-step-list.k-step-list-horizontal>
li.k-step.k-step-done> a.k-step-link> span.k-step-indicator> span.stepper-resumen-n{
  content: url('/icons/ICONOGRAFIA/stepper/RESUMEN_STEPPER.svg');
}

nav.k-stepper.finbe-stepper-horizontal> ol.k-step-list.k-step-list-horizontal>
li.k-step.k-step-current> a.k-step-link> span.k-step-indicator> span.stepper-resumen-n  {
  content: url('/icons/ICONOGRAFIA/stepper/RESUMEN_STEPPER.svg');
}

/** Stepper Visualize one **/
nav.k-stepper.finbe-stepper-horizontal> ol.k-step-list.k-step-list-horizontal>
li.k-step.k-step-done> a.k-step-link> span.k-step-indicator> span.stepper-uno-n{
  content: url('/icons/ICONOGRAFIA/stepper/1.svg');
}

nav.k-stepper.finbe-stepper-horizontal> ol.k-step-list.k-step-list-horizontal>
li.k-step.k-step-current> a.k-step-link> span.k-step-indicator> span.stepper-uno-n  {
  content: url('/icons/ICONOGRAFIA/stepper/1.svg');
}

/** Stepper Visualize two **/
nav.k-stepper.finbe-stepper-horizontal> ol.k-step-list.k-step-list-horizontal>
li.k-step.k-step-done> a.k-step-link> span.k-step-indicator> span.stepper-dos-n{
  content: url('/icons/ICONOGRAFIA/stepper/2.svg');
}

nav.k-stepper.finbe-stepper-horizontal> ol.k-step-list.k-step-list-horizontal>
li.k-step.k-step-current> a.k-step-link> span.k-step-indicator> span.stepper-dos-n  {
  content: url('/icons/ICONOGRAFIA/stepper/2.svg');
}

/** Stepper Visualize accepted **/
nav.k-stepper.finbe-stepper-horizontal> ol.k-step-list.k-step-list-horizontal>
li.k-step.k-step-done> a.k-step-link> span.k-step-indicator> span.stepper-aprobado-n{
  content: url('/icons/ICONOGRAFIA/stepper/APROBADO STEPPER.svg');
}

nav.k-stepper.finbe-stepper-horizontal> ol.k-step-list.k-step-list-horizontal>
li.k-step.k-step-current> a.k-step-link> span.k-step-indicator> span.stepper-aprobado-n  {
  content: url('/icons/ICONOGRAFIA/stepper/APROBADO STEPPER.svg');
}

/** Stepper Visualize two **/
nav.k-stepper.finbe-stepper-horizontal> ol.k-step-list.k-step-list-horizontal>
li.k-step.k-step-done> a.k-step-link> span.k-step-indicator> span.stepper-tres-n{
  content: url('/icons/ICONOGRAFIA/stepper/3.svg');
}

/** Stepper Visualize accepted **/
nav.k-stepper.finbe-stepper-horizontal> ol.k-step-list.k-step-list-horizontal>
li.k-step.k-step-done> a.k-step-link> span.k-step-indicator> span.stepper-aceptado{
  content: url('/icons/ICONOGRAFIA/stepper/APROBADO STEPPER.svg');
}

nav.k-stepper.finbe-stepper-horizontal> ol.k-step-list.k-step-list-horizontal>
li.k-step.k-step-current> a.k-step-link> span.k-step-indicator> span.stepper-aceptado  {
  content: url('/icons/ICONOGRAFIA/stepper/APROBADO STEPPER.svg');
}

nav.k-stepper.finbe-stepper-horizontal> ol.k-step-list.k-step-list-horizontal>
li.k-step.k-step-current> a.k-step-link> span.k-step-indicator> span.stepper-tres-n  {
  content: url('/icons/ICONOGRAFIA/stepper/3.svg');
}

/** Stepper size title **/
nav.k-stepper.finbe-stepper-horizontal> ol.k-step-list.k-step-list-horizontal>
li.k-step> a.k-step-link> span.k-step-indicator> span.k-icon  {
  font-size: 1.5em;
}

/** Stepper change position progress bar**/
nav.k-stepper.finbe-stepper-horizontal> div.k-widget.k-progressbar.k-progressbar-horizontal  {
  margin: 1.1em;
}

/** Stepper change color progress bar **/
nav.k-stepper.finbe-stepper-horizontal> div.k-widget.k-progressbar .k-state-selected {
    background-color: #fa717f;
}

/** Stepper change size label title **/
nav.k-stepper.finbe-stepper-horizontal> ol.k-step-list.k-step-list-horizontal>
li.k-step> a.k-step-link> span.k-step-label> span.k-step-text {
    max-width: calc(15em - 16px - 0.5rem);
}

/** Change style upload input removed background**/
.k-upload {
    background-color: transparent !important;
}

/** Change style upload input removed background **/
.k-upload .k-dropzone {
    background-color: transparent !important;
}

/** Change style upload input add border style **/
.k-widget.k-upload.k-header{
  border-style: groove !important;
  border-color: #89959a !important;
}

/** Change style upload input color button **/
.k-upload-button {
    color: #44444a;
    background-color: #d3d3d3;
}

/** Change desing button in table **/
button.k-button.k-button-icontext.finbe-button.action{
  width: 2em;
  padding-right: 0.5em;
  background-color: transparent;
  border-color: transparent;
}

/** Button action table **/
.k-button:disabled, .k-button.k-state-disabled {
  opacity: 0.50 !important;
  cursor: no-drop !important;
}

button.k-button.k-button-icontext.finbe-button.action:hover {
  border: transparent !important;
  background-color: #C2C2C2 !important;
  padding-bottom: 8px;
}

.k-icon.k-i-.icon.cargar_docs_activo{
  padding-left: 8px;
}

.k-icon.k-i-.icon.cargar_docs_sin_doc{
  padding-left: 8px;
}

.icon::before {
    margin: -2px;
}
/** Change desing dorp down list **/
div.k-form-field.finbe-drop-down-show-register {
  border-radius: 0.5em !important;
  margin-top: 0.4em;
  padding-left: 1.3em !important;
}

div.k-form-field.finbe-drop-down-show-register>div>span.k-header {
  background-color: transparent !important;
}

div.k-form-field.finbe-drop-down-show-register>div>span.k-header>span.k-dropdown-wrap.k-state-default  {
  border-color: #212529 !important;
  color: #212529 !important;
  background-color: transparent !important;
}

p.finbe-p-register{
  display: flex;
  align-items: center;
}

/** Input money **/
div.finbe-input-money> span.k-text-disabled {
  opacity: revert;
}

div.finbe-input-money> span.k-text-disabled>input {
  border-radius: 1em;
}

div.finbe-input-money> span {
  width: 100%;
}

div.finbe-input-money> span::before {
  content: url('/icons/ICONOGRAFIA/SIGNO-PESOS.svg');
  width: 1em;
  margin-left: 1.5em;
  margin-top: 0.29em;
  position: absolute;
}

/** Dialog footer acctions center **/
.finbe-dialog-center-buttons{
  align-self: center;
  justify-content: center;
  display: flex;
  padding-right: 1.2em;
  margin-top: 1em;
}

/** Dialog footer accionts no center **/
.finbe-dialog-buttons{
  justify-content: flex-end;
  display: flex;
  padding-right: 1.2em;
  margin-top: 1em;
}

/** Dialog success or danger **/
div.dialog-danger> div.k-dialog> div.k-header{
  background: #dc3545;
}

/** Input file width 100% **/
div.k-form-field-wrap.finbe-file> span.k-textbox-container{
  width: 100%;
  padding: 0em;
}
/** Input file without padding **/
.finbe-fieldWrapperStyle{
  padding: 0em;
}

/** **/
.k-radio:checked {
    border-color: #007bff !important;
    color: #ffffff !important;
    background-color: #007bff !important;
}

/** Button upload not show **/
div.finbe-file> span.k-textbox-container> div.k-form-field>
div> div.k-widget> div.k-action-buttons> button.k-upload-selected{
  display: none;
}

/*** TooltipTemplate **/
.k-tooltip {
  border-color: #8E8D8D;
  color: #ffffff;
  background-color: #8E8D8D;
  font-size: 16px;
  padding: 0.025rem 0.25rem !important;
}

.k-tooltip .k-callout {
  color: #8E8D8D;
}

/* dividir texto de menú */
/* .k-drawer-item .k-item-text{
  white-space:pre-line;
} */

.k-list-container{
  z-index: 10001;
}
