.k-form {
    color: rgb(42, 42, 49);
}

.k-form .k-textbox-container > input {
    background-color: rgba(154,154,154,0.3);
    border-radius: calc( calc(1.5em + 0.75rem + 2px) / 2 );
}

.k-form .k-form-legend {
    font-weight: bold;
    color: rgba(154,154,154,1);
}

.k-label, .k-radio-label {
    color: rgba(154,154,154,1);
    font-weight: bold;
}

.k-form .k-textbox-container > .k-label {
    font-weight: bold;
    font-size: 10pt;
    margin-top: 4pt;
    margin-left: 4pt;

    transform: translate(-0.75rem, -2.5rem);

    color: rgba(154,154,154,1);
}

.k-form .k-textbox {
    color: rgb(42, 42, 49);
}

.k-combobox {
    background: none;
}

.k-form .k-dropdown-wrap {
    border-radius: calc( calc(1.5em + 0.75rem + 2px) / 2 );
    overflow: hidden;
    background-color: rgba(154,154,154,0.3);
}

.k-form .k-dropdown-wrap > .k-select {
    background-color: rgba(154,154,154,0);
}

.k-radio:checked {
    border-color: rgba(244, 132, 31, 1);
    background-color: rgba(244, 132, 31, 1);
}

.k-searchbar {
    font-weight: normal;
}

.k-form ul.k-radio-list {
    margin-top: -16px;
}

.formButtons {
    margin-top: 1cm;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
}

.formButtons > button {
    border-radius: 17px !important;
    color: white;
    width: 3cm;
    margin-right: 1cm;
    font-weight: bold;
}

.formButtons > button:first-child {
    background-color: #9a9a9a;
}

.formButtons > button:last-child {
    background-color: rgba(244, 132, 31, 1);
}

.titleButtons {
    float: right;
    margin-top: -4px;
}

.titleButtons > button {
    border-radius: 17px !important;
    margin-right: 10px;
}
