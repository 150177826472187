.finbe-main-principal{
    width: 50%;
    height: 50%;
}
.finbe-principal-logo{
    width: 10em;
    padding-left: .5em;
    padding-top: 1em;
}
.finbe-principal-avatar{
    margin: 4% 25%;
    text-align: center;
}
.finbe-pricipal-title{
    color:#FFCF1B;
    text-align: center;
    margin: auto;
}
.finbe-principal-information{
    margin-bottom: 2em;
}
.finbe-principal-name{
    color:#FFCF1B;

}
.finbe-principal-name h2{
    margin: auto;
    text-align: center;
}
.finbe-principal-role{
    color: #9A9A9A;
}
.finbe-principal-role h3{
    margin: auto;
    text-align: center;
}