.config-element {
    margin-top: 8mm;
}

.config-element > .row > .k-form-field {
    padding-bottom: 1mm;
}

.config-element > .k-form-field > .k-form-field-wrap {
    display: inline;
}

.config-element > .k-form-field {
    display: inline;
}

.config-element .k-input {
    width: 17mm;
}

.config-element .k-label.element {
    color: #007bff;
    width: 30%;
    vertical-align: bottom;
}

.my-finbe-content-card {
    background-color: #f4f3ef;
}

.row {
    display: inline-flex;
}

.half-width {
    width: 50%;
}

.padding-right-1mm > .config-element > .k-label {
    padding-right: 8mm;
}

.padding-right-2mm > .config-element > .k-label {
    padding-right: 28mm;
}

.padding-right-3mm > .config-element > .k-label {
    padding-right: 18mm;
}