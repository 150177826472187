.modal-notificacion > .modal-notificacion-titulo {
    font-size: 26px;
    font-family: 'MontserratRegular';
    font-weight: bold;
    color: #5b5b5f;
}

.modal-notificacion > div > div.k-window-titlebar {
    background-color: white !important;
}

.modal-notificacion-tipo {
    font-family: 'MontserratRegular';
    font-weight: bold;
    color: #000000;
}

.modal-titulo-descripcion {
    font-size: 12pt;
    font-weight: bold;
    color: #5b5b5f;
}

.modal-contenido-descripcion {
    height: 50%;
    border-width: 1px;
    border-style: solid;
    border-color: #5b5b5f;
    border-radius: 5px;
}